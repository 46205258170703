<template>
  <div class="withdrawal-content a-flex-ccc">
    <img src="../../../assets/icon/success-icon.png" style="width: 69px; height: 69px" alt="" />
    <span class="a-c-master a-fw-700 fs22" style="padding: 26px 0 17px">注册申请已提交</span>
    <span class="a-c-normal a-fs-14" style="padding: 0 0 35px">请等待管理员审核通过</span>
    <div class="a-flex-rfsc">
      <el-button type="primary" class="a-ml-24 a-mt-15" @click="toBusinessCenter">
        返回客户管理
      </el-button>
      <el-button
        class="a-ml-24 a-mt-15"
        style="margin-left: 24px; width: 104px"
        @click="toWithdrawalDetails"
      >
        查看详情
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toBusinessCenter() {
      this.$store.dispatch("remove_current_view", this).then(() => {
        this.$router.push({
          path: "/customer/customer-list",
        });
      });
    },
    toWithdrawalDetails() {
      this.$store.dispatch("remove_current_view", this).then(() => {
        this.$router.push({
          path: "/customer/customer-detail",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawal-content {
  height: 100%;
  overflow-y: auto;
  background: #ffffff;
  font-size: 14px;
}

.fs22 {
  font-size: 22px;
}
</style>
