<template>
  <div class="minformation">
    <el-form
      ref="formationForm"
      :model="formationForm"
      :rules="rules"
      label-position="left"
      label-width="150px"
    >
      <el-form-item label="主体类型" prop="newType">
        <el-select v-model="formationForm.newType" placeholder="请选择" style="width: 400px">
          <el-option label="自然人" :value="0"></el-option>
          <el-option label="企业" :value="1"></el-option>
          <el-option label="个体工商户" :value="2"></el-option>
          <!-- <el-option label="政府及事业单位" :value="3"></el-option>
                    <el-option label="其他组织" :value="4"></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item label="商户简称" prop="brand">
        <el-input
          v-model="formationForm.brand"
          placeholder="请输入商户简称"
          style="width: 400px"
          maxlength="5"
        ></el-input>
      </el-form-item>
      <el-form-item label="商户全称" prop="name">
        <el-input
          v-model="formationForm.name"
          placeholder="请输入商户全称"
          style="width: 400px"
        ></el-input>
      </el-form-item>

      <le-input-district
        label="所在地区"
        :province.sync="formationForm.provinceCode"
        :city.sync="formationForm.cityCode"
        :area.sync="formationForm.districtCode"
        class="address"
        prop="city"
        :notnull="true"
        size="large"
        style="width: 550px"
      ></le-input-district>

      <template v-if="formationForm.newType == 1">
        <el-form-item label="法人姓名" prop="repName">
          <el-input
            v-model="formationForm.repName"
            placeholder="请输入法人姓名"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="repIdNumber">
          <el-input
            v-model="formationForm.repIdNumber"
            placeholder="请输入"
            style="width: 400px"
            @input="formationForm.repIdNumber = formationForm.repIdNumber.replace(/[^\w\/]/gi, '')"
          ></el-input>
        </el-form-item>

        <le-upload-file-img
          ref="repCardFrontImagePath"
          v-model="formationForm.repCardFrontImagePath"
          label="法人身份证正面照"
          :notnull="true"
          class="certImagePath a-mt-24"
        ></le-upload-file-img>

        <le-upload-file-img
          ref="repCardBackImagePath"
          v-model="formationForm.repCardBackImagePath"
          label="法人身份证反面照"
          :notnull="true"
          class="certImagePath a-mt-24"
        ></le-upload-file-img>
        <el-form-item label="是否三证合一" class="a-mt-24">
          <el-radio-group v-model="formationForm.is3in1">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="formationForm.is3in1 == 1" label="统一社会信用代码" prop="certNumber">
          <el-input
            v-model="formationForm.certNumber"
            placeholder="请输入统一社会信用代码"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="formationForm.is3in1 == 0" label="营业执照号" prop="ycertNumber">
          <el-input
            v-model="formationForm.ycertNumber"
            placeholder="营业执照号"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <le-upload-file-img
          ref="certImagePath"
          v-model="formationForm.certImagePath"
          label="营业执照"
          :notnull="true"
          class="certImagePath"
        ></le-upload-file-img>
        <el-form-item
          v-if="formationForm.is3in1 == 0"
          label="税务登记证号码"
          prop="taxRegister"
          class="a-mt-56"
        >
          <el-input
            v-model="formationForm.taxRegister"
            placeholder="请输入税务登记证号码"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <le-upload-file-img
          v-if="formationForm.is3in1 == 0"
          ref="taxRegisterImagePath"
          v-model="formationForm.taxRegisterImagePath"
          label="税务登记证照片"
          :notnull="true"
          class="certImagePath"
        ></le-upload-file-img>
      </template>
    </el-form>
    <!-- <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">下一步</el-button>
            <el-button class="a-ml-24 a-mt-15 " @click="cancelSubmit" style="margin-left:16px;">
                &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
        </div> -->
  </div>
</template>

<script>
import util from "../../../utils/validate";
export default {
  props: {
    formationForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const isNumAletter = (rule, value, callback) => {
      if (/^[0-9a-zA-Z]/.test(value)) {
        callback();
      } else {
        callback(new Error("输入格式不正确"));
      }
    };
    const isidcard = (rule, value, callback) => {
      if (util.validatIdCard(value)) {
        callback();
      } else {
        callback(new Error("输入格式不正确"));
      }
    };
    return {
      isReload: false,
      taxRegisterImagePath: "",
      repCardFrontImagePath: "",
      repCardBackImagePath: "",
      certImagePath: "",
      provinceCode: "",
      cityCode: "",
      districtCode: "",
      rules: {
        newType: [{ required: true, message: "请选择主体类型", trigger: "change" }],
        brand: [{ required: true, message: "请输入商户简称", trigger: "blur" }],
        name: [{ required: true, message: "请输入商户全称", trigger: "blur" }],
        city: [{ required: true, message: "请选择所在地区", trigger: "blur" }],
        repName: [{ required: true, message: "请输入法人姓名", trigger: "blur" }],
        repIdNumber: [
          { required: true, message: "请输入法人身份证号", trigger: "blur" },
          { validator: isidcard, trigger: "blur" },
        ],
        certNumber: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        ycertNumber: [{ required: true, message: "请输入营业执照号码", trigger: "blur" }],
        taxRegister: [
          { required: true, message: "请输入税务登记证号码", trigger: "blur" },
          { validator: isNumAletter, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (this.formationForm.repCardFrontImagePath) {
      this.isReload = true;
      this.repCardFrontImagePath = this.formationForm.repCardFrontImagePath;
    }
    if (this.formationForm.repCardBackImagePath) {
      this.isReload = true;
      this.repCardBackImagePath = this.formationForm.repCardBackImagePath;
    }
    if (this.formationForm.taxRegisterImagePath) {
      this.isReload = true;
      this.taxRegisterImagePath = this.formationForm.taxRegisterImagePath;
    }
    if (this.formationForm.certImagePath) {
      this.isReload = true;
      this.certImagePath = this.formationForm.certImagePath;
    }
    if (this.formationForm.provinceCode) {
      this.isReload = true;
      this.provinceCode = this.formationForm.provinceCode;
    }
    if (this.formationForm.cityCode) {
      this.isReload = true;
      this.cityCode = this.formationForm.cityCode;
    }
    if (this.formationForm.districtCode) {
      this.isReload = true;
      this.districtCode = this.formationForm.districtCode;
    }
    this.formationForm.repCardFrontImagePath = "";
    this.formationForm.repCardBackImagePath = "";
    this.formationForm.taxRegisterImagePath = "";
    this.formationForm.certImagePath = "";
    this.formationForm.provinceCode = "";
    this.formationForm.cityCode = "";
    this.formationForm.districtCode = "";
  },
  mounted() {
    if (this.isReload) {
      this.isReload = false;
      this.formationForm.repCardFrontImagePath = this.repCardFrontImagePath;
      this.formationForm.repCardBackImagePath = this.repCardBackImagePath;
      this.formationForm.taxRegisterImagePath = this.taxRegisterImagePath;
      this.formationForm.certImagePath = this.certImagePath;
      this.formationForm.provinceCode = this.provinceCode;
      this.formationForm.cityCode = this.cityCode;
      this.formationForm.districtCode = this.districtCode;
    }
    this.$set(this, "formationForm", this.formationForm);
  },
};
</script>

<style lang="scss" scoped>
.minformation {
  height: 100%;
}

/deep/ .el-form-item__label {
  padding-right: 30px;
  line-height: 22px;
  font-size: 14px;
}

/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 24px;
}

.address {
  padding-top: 0;
  margin-bottom: 12px;

  /deep/ .el-cascader .el-input .el-input__inner {
    width: 100%;
  }

  /deep/ .s-search-label {
    justify-content: flex-start;

    width: 124px;

    & + div {
      margin-left: 58px;
      width: 100%;
      // margin-left: 0;

      > div {
        width: 100%;
      }
    }
  }
}

.certImagePath {
  /deep/ .s-search-label {
    justify-content: flex-start;

    width: 150px !important;

    > span {
      margin-right: 4px;
    }
  }
}
</style>
